import React, {useEffect, useState} from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import "./RegisterInputs.css"
import axios from "axios";
import {message, Skeleton} from "antd";
import { encrypt, decrypt } from "n-krypta"
// import {Provider, useDispatch, useSelector} from "react-redux";
import {useNavigate, useOutletContext} from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NearMeIcon from '@mui/icons-material/NearMe';
import Loading from "../Loading/Loading"
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'js-cookie'
import env from "react-dotenv";




const RegisterInputs = () => {


    // const step = useSelector((state) => state.userinformation.step);
    // const formValues = useSelector((state) => state.userinformation.userInfo);
    // const dispatch = useDispatch();
    const {handleNextStep, accessToken} = useOutletContext();
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [loadingState, setLoadingState] = useState(false);

    const [userName, setUserName] = useState("")
    const [userNameError, setUserNameError] = useState(false);
    const [nameCenter, setNameCenter] = useState("");
    const [localphone, setLocalPhone] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [somePassword, setSomePassword] = useState(false);
    const [rePassword, setRePassword] = useState("");
    const [rePasswordError, setRePasswordError] = useState(false);
    const [provincesID, setProvincesID] = useState("");
    const [provincesName, setProvincesName] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [ListProvinces, setListProvinces] = useState([]);
    const navigate = useNavigate()
    const [ShowError, setShowError] = useState(false);


    function isSomePassword(password, rePassword) {

        if (password !== rePassword) {
            setSomePassword(true)
        } else setSomePassword(false)

    }


    function isNumber(value) {

        try {
            if (isNaN(value)) {
                throw new Error();
            } else {
                if (value.length !== 11) {
                    setUserNameError(true);
                } else {
                    if (value[0] === '0' && value[1] === '9') {
                        setUserNameError(false);

                    } else {
                        setUserNameError(true);

                    }
                }
            }
        } catch (error) {
            setUserNameError(true)
        }

    }


    function isEmpty() {

        setNameError(false)
        setLastNameError(false)
        setPasswordError(false)
        setRePasswordError(false)

        if (name.length < 1) setNameError(true)
        if (lastName.length < 1) setLastNameError(true)
        if (password.length < 1) setPasswordError(true)
        if (rePassword.length < 1) setRePasswordError(true)

    }

    function checkErrorInputs() {

        setShowError(true);
        if (((userNameError || nameError || lastNameError) === false))
            submitRegister()

    }

    const submitRegister = () => {
        setLoading(true)
        axios.post(`${env.API_URL}/user/v1/register`, {
            mobile: userName,
            password: password,
            org_name: nameCenter,
            state: provincesID,
            last_login: "",
            first_name: name,
            last_name: lastName,
            address: address,
            phone: localphone,
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                    message.success(response.data.message);
                    setLoading(false);
                    navigate("/register/step2");
                }
            })
            .catch((error) => {
                setLoading(false)
                if (error.message === "Network Error") {
                    message.error("اینترنت خود را بررسی کنید ..!")
                }
                if (error.response && error.response.status <= 499) {
                    message.error(error.response.data.message);
                } else {
                    message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...");
                }
            });
    };

    useEffect(() => {
        setLoadingState(true)
        axios.get(`${env.API_URL}/states/v1/get-state-list`)
            .then((response) => {
                console.log(response)
                setListProvinces(response.data.data.docs);
                setLoadingState(false)
            }).catch((error) => {
            setLoadingState(true)
            if (error.message === "Network Error")
                message.error("اینترنت خود را بررسی کنید ..!")
        })
    }, []);

    useEffect(() => {
        isNumber(userName)
        isEmpty()
        isSomePassword(password, rePassword)
    }, [userName, name, lastName, password, rePassword])


    function selectProvinces(props) {
        setProvincesID(props._id)
        setProvincesName(props.name)
        setShowDropdown(false)
    }

    return (
        <div className="flex justify-center">
            <div className="w-[90%] pb-[128px] md:w-full">

                <div className="grid grid-cols-2 h-[7vh] md:flex md:flex-col md:w-full md:gap-[12px] md:h-[22vh]">

                    <div className="col-span-1 inline md:h-[10vh]">
                        <div className="w-[100%] flex justify-center">
                            <div
                                className={`inputFild ${ShowError ? userNameError ? `border-[2px] border-red-400` : null : null}`}>
                                <PersonOutlineIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                                <input
                                    type="number"
                                    id="userName"
                                    placeholder=" "
                                    className="input outline-none bg-transparent w-full h-full text-[18px]"
                                    maxLength={11}
                                    onChange={(e) => setUserName(e.target.value)}
                                    value={userName}
                                />
                                <label className="label flex gap-1" htmlFor="userName">
                                    <p className="text-red-500 mt-1">*</p> نام کاربری (شماره موبایل)
                                </label>
                            </div>
                        </div>
                        {ShowError ?
                            <>
                                {userNameError ?
                                    <div className="mt-1 text-red-500 text-[10px] flex justify-center">
                                        <p>شماره تماس را به صورت صحیح وارد کنید</p>
                                    </div>
                                    : null}
                            </>
                            : null}

                    </div>

                    <div className="col-span-1 inline md:h-[10vh]">
                        <div className="w-[100%] flex justify-center">
                            <div className="inputFild">
                                <StorefrontOutlinedIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                                <input
                                    type="text"
                                    id="nameCenter"
                                    placeholder=" "
                                    className="input outline-none bg-transparent w-full h-full text-[18px]"
                                    onChange={(e) => setNameCenter(e.target.value)}
                                    value={nameCenter}
                                />
                                <label className="label" htmlFor="nameCenter">
                                    نام مرکز
                                </label>
                            </div>
                        </div>
                    </div>

                </div>

                <div
                    className="grid grid-cols-2 max-h-[300px] mt-7 md:mt-[12px] md:flex md:flex-col md:h-[22vh] md:w-full md:gap-[12px]">

                    <div className="col-span-1 inline md:h-[10vh]">
                        <div className="flex justify-center">
                            <div className="inputFild"
                                 onClick={() => {
                                     setShowDropdown(showDropdown => !showDropdown)
                                 }}>
                                <LocationOnOutlinedIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                                <input
                                    type="text"
                                    id="state"
                                    placeholder=" "
                                    className="input outline-none hover:cursor-context-menu bg-transparent w-full h-full text-[18px]"
                                    value={provincesName}
                                />
                                <label className="label" htmlFor="state">
                                    استان
                                </label>
                                <div className={`ml-3 ${showDropdown ? `rotate-180` : null}`}>
                                    <KeyboardArrowDownIcon style={{fontSize: "30px"}}/></div>
                            </div>
                        </div>

                        <div className="flex justify-center mt-2">
                            {showDropdown ?
                                loadingState ?
                                    <div
                                        className="z-10 absolute overflow-y-scroll md:h-[25vh] flex justify-center p-7 h-[21vh] w-[31%] md:w-[90%] bg-lightColor rounded-[5px]">
                                        <ul className="flex flex-col gap-3 w-full">
                                            <li className="box-border text-mainColor">
                                                <Skeleton active paragraph={{rows: 4, width: "100%"}} title={false}/>
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    <div
                                        className="z-10 absolute overflow-y-scroll md:h-[25vh] h-[21vh] w-[31%] md:w-[90%] bg-lightColor rounded-[5px]">
                                        <ul>
                                            {ListProvinces.map((product, index) => (
                                                <li key={index} className="m-3 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                    onClick={() => selectProvinces(product)}
                                                >
                                                    <NearMeIcon className="ml-[5px] text-mainColor"/>{product.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                :
                                null
                            }
                        </div>
                    </div>

                    <div className="col-span-1 w-[100%] md:h-[10vh] flex justify-center">
                        <div className="inputFild">
                            <CallOutlinedIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                            <input
                                type="text"
                                id="localphone"
                                placeholder=" "
                                className="input outline-none bg-transparent w-full h-full text-[18px]"
                                maxLength={11}
                                onChange={(e) => setLocalPhone(e.target.value)}
                                value={localphone}
                            />
                            <label className="label" htmlFor="localphone">
                                تلفن ثابت
                            </label>
                        </div>
                    </div>
                </div>

                <div
                    className="grid grid-cols-2 h-[8vh] md:h-[22vh] mt-7 md:mt-[12px] md:flex md:flex-col md:w-full md:gap-[12px]">
                    <div className="col-span-1 inline md:h-[10vh]">
                        <div className="w-[100%] flex justify-center">
                            <div
                                className={`inputFild ${ShowError ? nameError ? `border-[2px] border-red-400` : null : null}`}>
                                <PersonOutlineIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                                <input
                                    type="text"
                                    id="name"
                                    placeholder=" "
                                    className="input outline-none bg-transparent w-full h-full text-[18px]"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                                <label className="label flex gap-1" htmlFor="name">
                                    <p className="text-red-500 mt-1">*</p> نام
                                </label>
                            </div>
                        </div>
                        {ShowError ?
                            <>
                                {nameError ?
                                    <div className="mt-1 text-red-500 text-[10px] flex justify-center">
                                        <p>پر کردن این فیلد الزامی است</p>
                                    </div>
                                    : null}
                            </>
                            : null}
                    </div>


                    <div className="col-span-1 inline md:h-[10vh]">
                        <div className="w-[100%] flex justify-center">
                            <div
                                className={`inputFild ${ShowError ? lastNameError ? `border-[2px] border-red-400` : null : null}`}>
                                <PersonOutlineIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                                <input
                                    type="text"
                                    id="lastName"
                                    placeholder=" "
                                    className="input outline-none bg-transparent w-full h-full text-[18px]"
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                />
                                <label className="label flex gap-1" htmlFor="lastName">
                                    <p className="text-red-500 mt-1">*</p> نام خانوادگی
                                </label>
                            </div>
                        </div>
                        {ShowError ?
                            <>
                                {lastNameError ?
                                    <div className="mt-1 text-red-500 text-[10px] flex justify-center">
                                        <p>پر کردن این فیلد الزامی است</p>
                                    </div>
                                    : null}
                            </>
                            : null}
                    </div>
                </div>

                

                <div
                    className="grid grid-cols-2 h-[8vh] mt-7 md:flex md:flex-col md:h-[22vh] md:mt-[12px] md:w-full md:gap-[12px]">

                    <div className="col-span-1 md:h-[10vh] w-[100%] flex justify-center">
                        <div className="inputFild">
                            <LocalPostOfficeOutlinedIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                            <input
                                type="text"
                                id="email"
                                placeholder=" "
                                className="input outline-none bg-transparent w-full h-full text-[18px]"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            <label className="label" htmlFor="email">
                                آدرس ایمیل
                            </label>
                        </div>
                    </div>

                    <div className="col-span-1 md:h-[10vh]">
                        <div className="w-[100%] flex justify-center">
                            <div className="inputFild">
                                <LocationOnOutlinedIcon style={{fontSize: "26px"}} className="text-mainColor"/>
                                <input
                                    type="text"
                                    id="address"
                                    placeholder=" "
                                    className="input outline-none bg-transparent w-full h-full text-[18px]"
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                />
                                <label className="label" htmlFor="address">
                                    آدرس
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 md:flex md:flex-col md:w-full md:gap-8">
                    <div className="w-[93%] md:w-[95%] flex justify-end">
                        <button
                            onClick={checkErrorInputs}
                            className="bg-mainColor h-[50px] w-[38%] md:w-[95%] md:h-[50px] flex items-center justify-center rounded-[5px]">
                            {loading ? (<Loading color={"bg-white"}/>) : (<div>
                                <p className="text-white text-[18px]">ثبت اطلاعات</p>
                            </div>)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterInputs;
