import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <div className="footer text-center bg-gray-100 p-2 text-sm fixed left-0 md:text-[12px] bottom-0 w-full">
      تمام حقوق مادی و معنوی این سایت متعلق به
      <Link to={"/"} className="font-bold">
        {" "}
        کیمی پدیا{" "}
      </Link>{" "}
      می باشد
    </div>
  );
}
