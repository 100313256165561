import React from "react"
import {useNavigate} from "react-router-dom";


const Factor = () => {

    const navigate = useNavigate()

    const handlenavigate =()=>{
        navigate("/")
    }


    return (<div className="flex justify-center mt-6">
        <div className="w-[90%]">
            <div className="flex flex-col gap-4">
                <div className=" gap-3 flex flex-col bg-graycolor h-fit W-full p-4 pb-6 rounded-[5px]">
                    <div className="bg-Headercolor w-fit p-1 pr-2 pl-2 rounded-[5px]">
                        <p>اطلاعات هویتی</p>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>نام و نام خانوادگی :</p>
                                <p>رضا احمدی</p>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>تلفن همراه : </p>
                                <p>0913121212</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>نام مرکز :</p>
                                <p>داروخانه دکتر صمدی</p>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>تلفن ثابت : </p>
                                <p>0913121212</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" gap-3 flex flex-col bg-graycolor h-fit W-full p-4 pb-6 rounded-[5px]">
                    <div className="bg-Headercolor w-fit p-1 pr-2 pl-2 rounded-[5px]">
                        <p>اطلاعات پکیج انتخاب شده</p>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>پکیج دوم _ 3 ماهه</p>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>تاریخ خرید:</p>
                                <p>1403-02-01</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1">
                            <div className="flex gap-1">

                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>تاریخ انقضا:</p>
                                <p>1403-02-01</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className=" gap-3 flex flex-col bg-graycolor h-fit W-full p-4 pb-6 rounded-[5px]">
                    <div className="bg-Headercolor w-fit p-1 pr-2 pl-2 rounded-[5px]">
                        <p>اطلاعات فاکتور</p>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>قیمت پکیج انتخابی:</p>
                                <p> 1,200,000 ریال</p>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>مالیات بر ارزش افزوده: </p>
                                <p>120,000 ریال</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1">
                            <div className="flex gap-1">
                                <p>قیمت قابل پرداخت: </p>
                                <p>1,320,000 ریال</p>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="flex gap-2 items-center">
                                <input
                                    type="checkbox"
                                    id="custom-checkbox"
                                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                />
                                <label htmlFor="custom-checkbox">
                                    مایل به دریافت فاکتور هستم. </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div onClick={handlenavigate} className="flex justify-end w-full h-[48px] items-center mt-2">
                <button
                    className="bg-mainColor w-[35%] rounded-[5px] h-full "
                >
                    <p className="text-white text-[18px]">ثبت اطلاعات</p>
                </button>
            </div>
        </div>
    </div>)
}

export default Factor;