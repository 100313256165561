import logo from "../../images/kimipedia-logo.png";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {useDispatch, useSelector} from "react-redux";
import HeaderTiltle from "./HeadersTiltle";
import DehazeIcon from "@mui/icons-material/Dehaze";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from "axios";
import {CheckAndHandleError} from "../../axios/HandleAcsess";
import {setUser} from "../slices/registerReducer";
import env from "react-dotenv";

export default function Header ({refresh}) {

    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDropDown, setShowDropDown] = useState(false);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const profileInformation = useSelector(state => state.userinformation.userInfo);

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    const handleLogOut = () => {
        localStorage.clear()
        window.location.reload()
    }

    useEffect(() => {
        axios.get(`${env.API_URL}/user/v1/get-my-profile`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(response => {
                const profileData = response.data.data;
                const transformedProfile = {
                    name: profileData.first_name,
                    lastName: profileData.last_name,
                    idCode: profileData.idCode,
                    birthDate: profileData.birthDate,
                    gender: profileData.gender,
                    address: profileData.org_name,
                    status: profileData.status,
                    mobile: profileData.mobile,
                    accessToken: localStorage.getItem('accessToken'),
                    loadingProfile: false,
                };
                dispatch(setUser(transformedProfile));
            })
            .catch(error => {
                CheckAndHandleError(error);
            });
    }, [dispatch, refresh]);


    return (
        <>
            <div className="header flex justify-between items-center md:p-4 p-7 bg-[#F7F7F7]">
                <div className="menu-items gap-5 text-[15px] md:w-[30vh]">
                    {isMobile ?
                        <button className="border-[2px] border-white bg-white text-[#024987] rounded-[40%]"
                                onClick={() => setShowDropDown(true)}><DehazeIcon/>
                        </button>
                        :
                        <HeaderTiltle/>}
                </div>
                <div className="logo ">
                    <Link to={"/"}>
                        <img alt="logo" src={logo} className="w-[200px]"/>
                    </Link>
                </div>
            </div>
            {showDropDown ?
                <HeaderTiltleMobile setShowDropDown={setShowDropDown} profileInformation={profileInformation}/> : null}
        </>
    )
}



export function HeaderTiltleMobile({setShowDropDown, profileInformation}) {


    const [showInfomationMedicine, setShowInfomationMedicine] = useState(false);
    const navigate = useNavigate();


    const handlelogout = () => {
        localStorage.clear()
        navigate("/")
    }


    return (
        <div
            className="hidden md:fixed md:z-10 md:flex md:flex-col-2 md:top-0 md:bg-gray-600 md:bg-opacity-75 md:h-full md:w-full">
            <div
                className="md:col-span-1 md:flex md:flex-col md:w-[65%] md:h-full md:bg-white md:rounded-l-[20px] md:gap-6">
                <Link to={"/Profile"}
                      className="md:flex md:flex-col md:gap-3 md:items-center md:justify-center md:mt-10">
                    <div
                        className="md:border-[3px] md:border-black md:rounded-[100%] md:w-[100px] md:h-[100px] md:text-[20px] md:text-center">
                        <PersonOutlineIcon style={{fontSize: "60px", width: "90%", height: "90%"}}/></div>
                    <h5 className="md:text-[20px]">{profileInformation.name} {profileInformation.lastName}</h5>
                </Link>
                <div>
                    <div className="md:mr-6 md:flex md:justify-between"
                         onClick={() => setShowInfomationMedicine(!showInfomationMedicine)}>
                        <h5>اطلاعات دارویی
                        </h5>
                        <div className={`md:text-card2 ml-3 ${showInfomationMedicine ? `md:rotate-180 ` : null}`}>
                            <KeyboardArrowDownIcon style={{fontSize: "30px"}}/>
                        </div>
                    </div>
                    {showInfomationMedicine ?
                        <div className=" md:flex md:flex-col md:mt-3 bg-gray-200 ">
                            <Link to={"/medicineA"} className=" cursor-pointer md:mr-7 md:text-[14px] md:m-3">
                                اطلاعات دارویی (دسته بندی A)</Link>
                            <Link to={"/medicineB"} className="md:mr-7 md:text-[14px] md:m-3">اطلاعات دارویی (دسته بندی
                                B)</Link>
                        </div>
                        : null}
                </div>
                <div className="md:mr-6">درباره ما</div>
                <div className="md:mr-6">راهنمای سایت</div>
                <div className="md:mr-6">قوانین و مقررات</div>
                <div className="md:mr-6">مقالات</div>
                <div className="md:mr-6">اخبار</div>
                <div onClick={handlelogout}
                     className="md:mr-6 text-red-500 md:flex md:justify-between">
                    <h5> خروج از حساب کاربری</h5>
                    <ExitToAppIcon style={{marginLeft: "12px"}}/>
                </div>
            </div>
            <div className="col-span-1 w-[35%]" onClick={() => setShowDropDown(false)}></div>
        </div>
    )
}
