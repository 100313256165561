import Header from "../Headers/Header";
import Footer from "../Footer/Footer";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import {useState, useEffect, React} from "react";
import axios from "axios";
import {CheckAndHandleError} from "../../axios/HandleAcsess";
import Needpayment from "./Needpayment";
import {message, Skeleton} from "antd";
import Loading from "../Loading/Loading";
import {TEChart} from "tw-elements-react";
import TimelineIcon from "@mui/icons-material/Timeline";
import env from "react-dotenv";


function MedicineDetailB() {

    const navigate = useNavigate();
    const location = useLocation();


    const {medicineId} = useParams();
    const [medicineData, setMedicineData] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [showChartModal, setShowChartModal] = useState(false);
    const [dataChartModal, setDataChartModal] = useState({});

    const [error, setErrore] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    useEffect(() => {
        setLoading(true);
        axios.get(`${env.API_URL}/medicine/v1/get-medicine-b-by-id/${medicineId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        })
            .then(response => {
                setMedicineData(response.data.data);
                setLoading(false)
            })
            .catch(error => {
                if (error.message === "Network Error") {
                    message.error("اینترنت خود را بررسی کنید ..!")
                }
                if (error.response && error.response.status <= 499) {
                    if (error.response.status === 403) {
                        setErrore(true)
                    } else
                        message.error(error.response.data.message);
                } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
                setLoading(false)

                CheckAndHandleError(error);
            });
    }, [medicineId]);

    function handleClick() {
        setShowModal(!showModal);
    }

    function handleClosePage() {
        navigate(-1);
    }

    function ChartForModal(data, text) {
        setDataChartModal({
            main: data,
            text: text,
        })
        setShowChartModal(true)
    }

    return (
        <>
            <Header/>
            <div className="main">
                <div className="title h-[180px] bg-no-repeat bg-cover flex items-center justify-center">
                    <h2 className="text-center w-full bg-[#ffffff36] pt-4 pb-4 font-extrabold text-[20px]">
                        {error ? (<div>لطفا اشتراک تهیه
                            کنید</div>) : (medicineData ? medicineData.brand_name_fa ?? "بدون نام" : 'لطفا صبر کنید...')}
                    </h2>
                </div>
                {error ? (<Needpayment/>) : (<div
                    className="information pt-10 w-[80%] md:flex md:flex-col md:w-[88%] m-auto grid grid-cols-2 gap-x-10 gap-y-7 auto-cols-auto pb-20">
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            نام برند دارویی به فارسی
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                (medicineData.brand_name_fa ?? "-")}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            نام برند دارویی به لاتین
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData?.brand_name_la ?? "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            نام ژنریک دارویی به فارسی
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.generic_name_fa ?? "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            نام ژنریک دارویی به انگلیسی
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.generic_name_la ?? "-"}
                        </div>
                    </div>

                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            قیمت فروش به توزیع کننده
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.price_distributor || "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            قیمت فروش به داروخانه
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.price_pharmacy || "-"}
                        </div>
                    </div>

                    <div className="flex">

                        <div className="card-info w-full">
                            <h3 className="info-title">
                                <ChevronLeftIcon className="text-mainColor"/>
                                قیمت فروش به مصرف کننده
                            </h3>
                            <div className="items-center flex p-3 bg-gray-100 rounded-lg justify-between min-h-[48px]">
                                {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                    (medicineData?.price_consumer ? medicineData?.price_consumer?.[medicineData.price_consumer?.length - 1].price : "-")}
                                <div className="text-mainColor hover:cursor-pointer"
                                     onClick={() => ChartForModal(medicineData?.price_consumer, "قیمت فروش به مصرف کننده")}>
                                    <TimelineIcon/>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            تعداد در بسته بندی
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.quantity_in_pack || "-"}
                        </div>
                    </div>

                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            شرکت تامین کننده
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.supplier_company ?? "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            شرکت تولید کننده
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.producer_company ?? "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            کد ژنریک دارو
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.generic_code ?? "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            کد IRC
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.irc_code ?? "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            کد GTIN
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.gtin_code ?? "-"}
                        </div>
                    </div>


                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            گروه دارویی
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.medicine_group || "-"}
                        </div>
                    </div>
                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            توضیحات
                        </h3>
                        <div className="info-box p-3 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ? <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/> :
                                medicineData.description ?? "بدون توضیحات"}
                        </div>
                    </div>

                    <div className="card-info">
                        <h3 className="info-title">
                            <ChevronLeftIcon className="text-mainColor"/>
                            دارو های مرتبط
                        </h3>
                        <div
                            className="items-center flex pr-3 p-2 bg-gray-100 rounded-lg min-h-[48px]">
                            {loading ?
                                <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                :
                                <div className="flex flex-wrap">
                                    {medicineData?.related?.map((iteam) =>
                                        <p>{iteam.generic_name_fa ?? ""} -</p>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="buttons flex flex-row gap-4 col-start-2 items-end justify-end">
                        <button
                            className="border-[3px] h-[48px]  border-mainColor rounded-lg text-mainColor pt-2 pb-2 w-full flex gap-2 justify-center"
                            onClick={handleClick}
                        >
                            <ErrorOutlineIcon/>
                            ارسال گزارش
                        </button>
                        <button
                            className="bg-mainColor h-[48px]  text-white pt-2 pb-2 col-start-1 col-end-2 w-full  rounded-lg"
                            onClick={handleClosePage}
                        >
                            بستن
                        </button>
                    </div>

                </div>)}


            </div>
            <Footer/>
            {showModal ? (
                <Modal showModal={showModal} medicineId={medicineId} setShowModal={setShowModal}/>
            ) : (
                ""
            )}
            {showChartModal ? (
                    <ChartModal data={dataChartModal} showModal={setShowChartModal}/>
                ) :
                null
            }
        </>
    );
}

export default MedicineDetailB;


export function Modal({showModal, setShowModal, medicineId}) {


    function handleClick() {
        setShowModal(!showModal);
    }


    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [loading, setLoading] = useState(false)

    function sendReportDataToApi() {

        const dateReport = {
            title: title,
            report: description,
            medicenID_B: medicineId,
        }

        setLoading(true)
        axios.post(`${env.API_URL}/report/v1/new_Report`, dateReport, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then((response) => {
            message.success(response.data.message)
            setShowModal(false)
            setLoading(false)
        }).catch((error) => {
            CheckAndHandleError(error);
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }
            if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
            setLoading(false)
        })

    }

    return (
        <div className={"report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"}>
            <div className="flex min-h-full items-end justify-center p-3 text-center">
                <div
                    className="modal relative transform overflow-hidden rounded-lg bg-white w-[60%] md:w-[88%] m-auto flex flex-col gap-5">
                    <div className="close-bar p-7 flex justify-between border-b-2">
                        <div className="bar-title">ارسال گزارش</div>
                        <div className="cursor-pointer bar-icon text-mainColor" onClick={handleClick}>
                            <CloseIcon/>
                        </div>
                    </div>
                    <div className="detail p-7 flex flex-col gap-10 font-normal justify-end">
                        <input
                            className="w-full text-right text-[15px] focus:outline-none focus:ring-2 focus:ring-mainColor placeholder:text-right bg-lightColor p-3 rounded-lg"
                            type="text"
                            placeholder="عنوان گزارش"
                            autoFocus
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <textarea
                            className="w-full text-right h-[200px] focus:outline-none focus:ring-2 resize-none outline-none focus:ring-mainColor text-[15px] placeholder:text-right bg-lightColor p-3 rounded-lg"
                            name="paragraph_text"
                            placeholder="متن گزارش خود را وارد کنید"
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        <button
                            className="p-3 bg-mainColor rounded-lg text-white md:w-1/2 md:m-auto w-2/6 self-end"
                            onClick={sendReportDataToApi}>
                            {loading ?
                                <div className="flex p-2 justify-center">
                                    <Loading color={"bg-white"}/>
                                </div>
                                : "ارسال گزارش"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function ChartModal({data, showModal}) {


    const [time, setTime] = useState([]);
    const [price, setPrice] = useState([])


    for (var i = 0; i <= data.main.length - 1; i++) {
        time.push(formatTimestamp(data.main[i].insert_date));
    }

    for (var i = 0; i <= data.main.length - 1; i++) {
        price.push(data.main[i]?.price);
    }


    function formatTimestamp(timestamp) {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    }


    return (
        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity">
            <div className="flex min-h-full items-end justify-center p-3 text-center">
                <div
                    className="modal relative transform overflow-hidden rounded-lg bg-white w-[60%] md:w-[88%] m-auto flex flex-col gap-5">

                    <div className="close-bar p-7 flex justify-between border-b-2">
                        <div className="bar-titel">{data.text}</div>
                        <div className="cursor-pointer hover:opacity-50 bar-icon text-mainColor"
                             onClick={() => showModal(false)}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="detail md:p-0 p-7 flex flex-col gap-10 font-normal justify-end">
                        <TEChart
                            type="line"
                            backgroundColor="#00C0C0"
                            data={{
                                labels: time,
                                datasets: [
                                    {
                                        backgroundColor: "#00C0C0",
                                        borderColor: "#00C0C0",
                                        label: "قیمت",
                                        data: price,
                                    },
                                ],
                            }}
                        />
                    </div>

                </div>
            </div>
        </div>

    )
}