import {React, useEffect, useState} from "react"
import {Modal} from "antd";
import {useNavigate} from "react-router-dom";
import img from "../../images/paymentpic.webp"
import {Skeleton} from "antd";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


const Needpayment = (open) => {

    const navigate = useNavigate()
    const [showModal, setShowModal] = useState()

    useEffect(() => {
        setShowModal(open)
    }, [open]);

    const handleCancel = () => {
        setShowModal(false)
        navigate(-1)
    };

    return (
        <>
            <Modal style={{fontFamily: "Vazir"}} title="لطفا اشتراک تهیه کنید" width={700} open={showModal}
                   footer={null} onCancel={handleCancel}>
                <div className="flex items-center md:flex md:flex-col-reverse md:gap-8">
                    <div className="w-1/2 md:w-full flex flex-col gap-8">
                        <h4 className="text-[18px] md:text-[16px] md:text-center">
                            برای مشاهده جزئیات داروها اشتراک تهیه کنید!
                        </h4>
                        <div className="flex justify-center">
                            <button className="w-[90%] bg-mainColor h-[44px] rounded-lg">
                                <p className="text-white text-[16px]">خرید اشتراک</p>
                            </button>
                        </div>
                    </div>
                    <div className="justify-center items-center flex w-1/2">
                        <img src={img} className="w-[260px]"/>
                    </div>
                </div>
            </Modal>

            <div
                className="information pt-10 w-[80%] m-auto md:flex md:flex-col md:w-[88%] grid grid-cols-2 gap-x-10 gap-y-7 auto-cols-auto pb-20">
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        نام برند دارویی به فارسی
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                    </div>
                </div>
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        نام برند دارویی به لاتین
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                    </div>
                </div>
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        نام ژنریک دارویی به فارسی
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                    </div>
                </div>
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        نام ژنریک دارویی به انگلیسی
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                    </div>
                </div>
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        صاحب امتیاز
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                    </div>
                </div>
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        کارخانه سازنده
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                    </div>
                </div>
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        شرکت وارد کننده
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1 , width:"100%"}} title={false}/>
                    </div>
                </div>
                <div className="card-info">
                    <h3 className="info-title">
                        <ChevronLeftIcon className="text-mainColor"/>
                        شرکت های توزیع کننده
                    </h3>
                    <div className="info-box p-3 bg-gray-100 rounded-lg">
                        <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Needpayment;