import "./App.css";
import {Routes, Route, useNavigate} from "react-router-dom";
import Login from "./components/Login & Register/Login";
import Register from "./components/Login & Register/Register";
import Step1 from "./components/Login & Register/Step1";
import Step2 from "./components/Login & Register/Step2";
import Step3 from "./components/Login & Register/Step3";
import MedicineA from "./components/Medicine/MedicineA"; // drug page
import MedicineB from "./components/Medicine/MedicineB";
import MedicineDetailA from "./components/Medicine/MedicineDetailA";
import Profile from "./components/Information/Profile/Profile";
import SelectPackage from "./components/Information/SelectPackage/SelectPackage";
import ManagementComponent from "./components/Information/Reports/ManagementComponent";
import MedicineDetailB from "./components/Medicine/MedicineDetailB";
import axios from "axios";
import {useEffect, useRef} from "react";
import ErrorPage404 from "./components/404 Page/404ErrorPage";
import {message} from "antd";
import {useLocation} from "react-router-dom";




function App() {


    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])



    return (
        <div
            onCopy={(e) => {
                e.preventDefault();
                return false;
            }}
            onDragStart={(e) => {
                e.preventDefault();
                return false;
            }}
        >
            <Routes>
                <Route path="*" element={<ErrorPage404/>}/>
                <Route path="/" element={<Login/>}/>
                <Route path="/Register" element={<Register/>}>
                    <Route path="step1" element={<Step1/>}/>
                    <Route path="step2" element={<Step2/>}/>
                    <Route path="step3" element={<Step3/>}/>
                </Route>
                <Route path="/medicineA" element={<MedicineA/>}/>
                <Route path="/medicineB" element={<MedicineB/>}/>
                <Route path="/medicine-A/:medicineId" element={<MedicineDetailA/>}/>
                <Route path="/medicine-B/:medicineId" element={<MedicineDetailB/>}/>
                <Route path="/Profile" element={<Profile/>}/>
                <Route path="/SelectPackage" element={<SelectPackage/>}/>
                <Route path="/Reports" element={<ManagementComponent/>}/>

            </Routes>
        </div>
    );
}

export default App;
