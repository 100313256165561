import Header from "../Headers/Header";
import Footer from "../Footer/Footer";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
// import numSeparator from "../../utils/numSeparator";
import "./medicine.css";
import {React, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {CheckAndHandleError} from "../../axios/HandleAcsess";
// import Loading from "../Loading/Loading";
import {message, Skeleton} from "antd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import env from "react-dotenv";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from '@mui/icons-material/Clear';
import {useMediaQuery} from "react-responsive";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import {useSelector} from "react-redux";
import Loading from "../Loading/Loading"


export default function MedicineA() {


    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const [showDisplayFilter, setShowDisplayFilter] = useState(false);

    const [getCompleteProfile, setGetCompleteProfile] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalDocs, setTotalDocs] = useState(0);
    const [showFilterPage, setShowFilterPage] = useState(false);
    const [itemPerPage, setItemPerPage] = useState(15)
    const [showDropdown, setShowDropdown] = useState(false);
    const [showButtonDeleteAllFilter, setShowButtonDeleteAllFilter] = useState(false);


    const [brandNameFA, setBrandNameFA] = useState("")
    const [brandNameEN, setBrandNameEN] = useState("")
    const [genericNameFA, setGenericNameFA] = useState("")
    const [genericNameEN, setGenericNameEN] = useState("")
    const [genericCode, setGenericCode] = useState("")
    const [quatityInPack, setQuatityInPack] = useState("")
    const [concessionaire, setConcessionaire] = useState("")
    const [searchAllFilter, setSearchAllFilter] = useState()

    const chips = [brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, concessionaire, quatityInPack, searchParams.get("search")]


    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    function deleteUrl(key) {

        // const searchParams = new URLSearchParams(window.location.search);
        if (key)
            searchParams.delete(key);
        navigate(location.pathname + "?" + searchParams.toString())

    }


    function handlePage(page) {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        } else setCurrentPage(1)
    }


    function sendDateSearchAllFilterToApi() {
        searchParams.set("search", searchAllFilter)
        navigate(location.pathname + "?" + searchParams.toString())
    }


    function selectedNumber(value) {
        setItemPerPage(value)
        setShowDropdown(false)
        setCurrentPage(1)
    }

    function deleteAllFilter() {

        // for (const [key] of searchParams) {
        //     deleteUrl(key)
        // }
        setSearchAllFilter("")
        navigate("/MedicineA")
        // setShowButtonDeleteAllFilter(false)

    }

    function check(s) {
        var PersianOrASCII = /[آ-ی]|([a-zA-Z])/;
        var m;
        if ((m = s.match(PersianOrASCII)) !== null) {
            if (m[1]) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    function deleteCustomFilter(event) {

        for (const [key, value] of searchParams) {
            if (value === event)
                deleteUrl(key)
        }

    }

    useEffect(() => {

        axios.get(`${env.API_URL}/user/v1/check-my-profile`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then((res) => {
            setGetCompleteProfile(res.data.data.profile_completed)
        }).catch((err) => {
            CheckAndHandleError(err)
        })

    }, []);

    useEffect(() => {

        if (searchAllFilter) {
            const delayedSetData = setTimeout(() => {
                searchParams.set("search", searchAllFilter);
                navigate(location.pathname + "?" + searchParams.toString());
                setCurrentPage(1);
            }, 800)

            return () => clearTimeout(delayedSetData);
        } else {
            searchParams.delete("search");
            navigate(location.pathname + "?" + searchParams.toString())
        }
    }, [searchAllFilter]);

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${env.API_URL}/medicine/v1/get-all-medicine-a-user`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
                    },
                    params: {
                        limit: itemPerPage,
                        page: currentPage,
                        search: searchParams.get("search"),
                        brand_name_fa: brandNameFA,
                        brand_name_la: brandNameEN,
                        generic_name_fa: genericNameFA,
                        generic_name_la: genericNameEN,
                        generic_code: genericCode,
                        concessionaire: concessionaire,
                        quantity_in_pack: quatityInPack,
                    }
                });
                const data = response.data.data.main;
                setTotalDocs(data.totalDocs)
                setCurrentItems(data.docs);
                setTotalPages(data.totalPages);
                setLoading(false)
            } catch (error) {
                CheckAndHandleError(error);
                setLoading(false)
                if (error.message === "Network Error") {
                    message.error("اینترنت خود را بررسی کنید ..!")
                }
                if (error.response && error.response.status <= 499) {
                    message.error(error.response.data.message);
                } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
            }
        };

        fetchData();
    }, [currentPage, itemPerPage, searchParams.get("search"), brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, quatityInPack, concessionaire]);
    // brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, quatityInPack, barndNameOwner


    useEffect(() => {
        // brandNameFA || brandNameEN || genericNameFA || genericNameEN || genericCode || barndNameOwner || quatityInPack || searchAllFilter
        // searchParams.get("brandNameFA") || searchParams.get("brandNameEN") || searchParams.get("genericNameFA") || searchParams.get("genericNameEN") || searchParams.get("genericCode") || searchParams.get("barndNameOwner") || searchParams.get("quatityInPack") || searchParams.get("searchAllFilter")
        if (Boolean(brandNameFA || brandNameEN || genericNameFA || genericNameEN || genericCode || concessionaire || quatityInPack || searchParams.get("search")))
            setShowButtonDeleteAllFilter(true)
        else setShowButtonDeleteAllFilter(false)

    }, [brandNameFA, brandNameEN, genericNameFA, genericNameEN, genericCode, quatityInPack, concessionaire, searchParams.get("search")]);


    useEffect(() => {

        setBrandNameFA(searchParams.get("brandNameFA") || "")
        setBrandNameEN(searchParams.get("brandNameEN") || "")
        setGenericNameFA(searchParams.get("genericNameFA") || "")
        setGenericNameEN(searchParams.get("genericNameEN") || "")
        setGenericCode(searchParams.get("genericCode") || "")
        setQuatityInPack(searchParams.get("quatityInPack") || "")
        setConcessionaire(searchParams.get("concessionaire") || "")
        setSearchAllFilter(searchParams.get("search") || "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.toString()]);


    return (
        <>
            <Header refresh={getCompleteProfile}/>
            <div className="main md:p-2 p-7 mb-12">
                <div
                    className="md:gap-3 md:flex md:flex-col article-header flex flex-row justify-between items-center md:items-start">
                    <div className="article-title flex flex-col gap-2">
                        <p className="md:text-[11px] md:flex font-normal text-mainColor">
                            &gt;&gt; دسته بندی A
                        </p>
                        <h2 className="text-[32px] md:text-[16px] font-black">
                            داروهای پایه (کلیه داروها - داروهای آزاد)
                        </h2>
                    </div>

                    <div className="flex gap-6">

                        <div className="md:flex md:flex-col hidden">

                            <div className="flex flex-col">
                                <p className="filter h-[27px] text-mainColor font-bold md:text-[12px] text-[14px] mb-[4px]">
                                    <FormatListNumberedRtlIcon style={{fontSize: "22px"}}/> تعداد نمایش </p>
                                <div
                                    className="w-[120px] flex justify-between hover:cursor-pointer rounded-[8px] border-[2px] text-right text-[15px] placeholder:text-right bg-lightColor p-2"
                                    onClick={() => setShowDropdown(!showDropdown)}>
                                    <p className="mr-2 text-gray-400">{itemPerPage}</p>
                                    <KeyboardArrowDownIcon
                                        className={`text-mainColor ${showDropdown ? `rotate-180` : null}`}/>
                                </div>
                            </div>

                            <div className="flex justify-center">
                                {showDropdown ?
                                    <div className="absolute flex justify-center mt-1">
                                        <ul className="flex flex-col gap-1 p-1 pr-4 w-[120px] bg-lightColor rounded-[8px]">
                                            <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                onClick={() => selectedNumber(15)}>
                                                15
                                            </li>
                                            <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                onClick={() => selectedNumber(30)}>
                                                30
                                            </li>
                                            <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                                onClick={() => selectedNumber(50)}>
                                                50
                                            </li>
                                        </ul>
                                    </div>
                                    : null}
                            </div>
                        </div>

                        <div className="search flex flex-col md:w-full md:justify-between">

                            <div
                                className="filter flex justify-between font-bold md:text-[12px] text-[14px] h-[26px] text-mainColor mb-[4px]">
                                <div className="hover:cursor-pointer" onClick={() => setShowFilterPage(true)}>
                                    <TuneIcon style={{fontSize: "22px"}}/> فیلتر جستجو
                                </div>
                                <div className="hidden md:flex md:hover:cursor-pointer">
                                    {showButtonDeleteAllFilter
                                        ?
                                        <button onClick={() => setShowDisplayFilter(true)}> نمایش فیلتر ها &gt;</button>
                                        :
                                        null}
                                </div>
                            </div>

                            <div className="search-input md:flex md:justify-end">
                                <input
                                    type="text"
                                    value={searchAllFilter}
                                    autoComplete="off"
                                    placeholder="عبارت مورد نظر را وارد کنید"
                                    className="search-f md:w-[80%] w-[250px] border-[2px] text-right text-[16px] md:placeholder:text-[12px] placeholder:text-right placeholder:text-gray-400 bg-lightColor p-2"
                                    onChange={(e) => {
                                        setSearchAllFilter(e.target.value)
                                    }}
                                />
                                <button className="serach-i bg-mainColor text-white p-2"
                                        onClick={sendDateSearchAllFilterToApi}
                                >
                                    <ManageSearchIcon/>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>


                <div className="md:hidden flex gap-3 mt-4 md:flex-col flex-row flex-wrap">
                    {showButtonDeleteAllFilter ?
                        <div
                            className="flex gap-1 bg-mainColor md:h-[4vh] opacity-75 rounded-[5pc] hover:cursor-pointer pl-3 pr-3 pt-1 pb-1"
                            onClick={deleteAllFilter}>

                            <p className="text-[12px] md:text-[10px] text-white font-[900] m-auto">حذف تمام فیلتر ها</p>
                        </div> : null}
                    {chips.map((iteam, index) => (
                        iteam &&
                        <div key={index}
                             className="flex gap-1 rounded-[5pc] bg-lightColor border-mainColor border-opacity-20 border-[2px] hover:cursor-pointer pl-3 pr-3 pt-1 pb-1"
                             onClick={() => deleteCustomFilter(iteam)}>
                            <p><ClearIcon style={{fontSize: "16px", color: "#00C0C0", margin: "auto"}}/></p>
                            <p className="text-[12px] md:text-[10px] opacity-65 text-mainColor font-black m-auto">{iteam}</p>
                        </div>
                    ))}
                </div>

                <div className="article-main mt-4">
                    <div>
                        <table className="text-center w-full text-sm rounded-[8px] border-2 border-gray-100">
                            <thead>
                            <tr className="bg-[#F9F9F9]">
                                <th></th>
                                <th>نام برند</th>
                                <th>نام ژنریک</th>
                                <th className="md:hidden">کارخانه سازنده</th>
                                <th className="md:hidden">صاحب امتیاز</th>
                                <th>عملیات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loading ? (
                                [...Array(7)].map((_, index) => (
                                    <tr key={index}>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className="box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className=" md:hidden box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                        <td className=" md:hidden box-border text-mainColor">
                                            <Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                currentItems.length > 0 ?
                                    currentItems.map((item, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-[#F9F9F9]'}>
                                            <td className="box-border text-mainColor">{index + 1 + (itemPerPage * (currentPage - 1))}</td>
                                            <td>{item.brand_name_fa.length > 30 ? <>{item.brand_name_fa.slice(0, 30) ?? "-"} ...</> : item.brand_name_fa ?? "-"}</td>
                                            <td className="md:hidden">{item.generic_name_fa.length > 30 ? <>{item.generic_name_fa.slice(0, 30) ?? "-"} ...</> : item.generic_name_fa ?? "-"}</td>
                                            <td className="md:hidden">{item.manufacturing_factory.length > 30 ? (check(item.manufacturing_factory) ? <>{item.manufacturing_factory.slice(0, 30) ?? "-"} ...</> : <>... {item.manufacturing_factory.slice(0, 30) ?? "-"}</>) : item.manufacturing_factory ?? "-"}</td>
                                            <td>{item.concessionaire.length > 30 ? (check(item.concessionaire) ? <>{item.concessionaire.slice(0, 30) ?? "-"} ...</> : <>... {item.concessionaire.slice(0, 30) ?? "-"}</>) : item.concessionaire ?? "-"}</td>
                                            <td className="text-thirdColor">
                                                <Link to={`/medicine-a/${item._id}`}>
                                                    <div className="flex items-center font-[300] justify-center gap-1">
                                                        <div
                                                            className="md:hidden rounded-[5px] p-1 w-auto h-auto flex items-center justify-center">
                                                            <SearchIcon style={{fontSize: "20px"}}/>
                                                        </div>
                                                        <span>نمایش اطلاعات</span>
                                                    </div>
                                                </Link>
                                            </td>
                                        </tr>
                                    )) :
                                    <tr className="bg-lightColor">
                                        <td className="text-[16px]" colSpan="6">دارویی یافت نشد!</td>
                                    </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalDocs > itemPerPage ?
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setItemPerPage={setItemPerPage}
                        setCurrentPage={setCurrentPage}
                        itemPerPage={itemPerPage}
                        onPageChange={handlePage}/>
                    :
                    null
                }
            </div>

            <Footer/>

            {showFilterPage ?
                <FindTitleByFilter setShowFilterPage={setShowFilterPage} setCurrentPage={setCurrentPage}/>
                :
                null
            }

            {isMobile ?
                showDisplayFilter ?
                    <ShowDisplayFilterMobile
                        setShowDisplayFilter={setShowDisplayFilter}
                        chips={chips} showButtonDeleteAllFilter={showButtonDeleteAllFilter}
                        deleteAllFilter={deleteAllFilter} deleteCustomFilter={deleteCustomFilter}/>
                    :
                    null
                :
                null}

            {getCompleteProfile ?
                null
                :
                <UpdateProfile setGetCompleteProfile={setGetCompleteProfile} getCompleteProfile={getCompleteProfile}/>

            }

        </>
    );
}


function Pagination({currentPage, totalPages, onPageChange, setItemPerPage, setCurrentPage, itemPerPage}) {

    const [valueSetPage, setValueSetPage] = useState();
    const [showDropdown, setShowDropdown] = useState(false);


    useEffect(() => {
        setValueSetPage(currentPage)
    }, [currentPage])


    function setNumberInputPage() {
        onPageChange(valueSetPage)
    }

    function selectedNumber(value) {
        setItemPerPage(value)
        setShowDropdown(false)
        setCurrentPage(1)
    }

    return (

        <div className="flex justify-center gap-5">

            <div className="flex flex-col">
                <div
                    className="pagination transition ease-in-out delay-150 pt-5 text-mainColor flex items-center justify-center gap-3">

                    <button
                        className={` ${currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >

                        <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                            <ArrowForwardIcon style={{fontSize: "18px"}}/>
                        </div>

                    </button>


                    <div className="flex text-black md:text-[12px] font-bold">
                        <p>{currentPage} از {totalPages} صفحه</p>
                    </div>


                    <button
                        className={` ${currentPage === totalPages ? "text-gray-400 cursor-not-allowed" : "text-mainColor"}`}
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >

                        <div className="border-2 border-mainColor h-[25px] rounded-[4px]">
                            <ArrowBackIcon style={{fontSize: "18px"}}/>
                        </div>

                    </button>


                </div>

                <div className="flex justify-center pt-2 items-center">

                    <input
                        className="w-[7.5rem] md:w-[6rem] bg-lightColor border-2 border-l-0 outline-none focus:border-2 pr-2 focus:border-mainColor focus:border-l-0 rounded-r-[5px]"
                        placeholder="برو به صفحه ..."
                        type="number"
                        onChange={(e) => setValueSetPage(e.target.value)}
                    />
                    <p className="bg-mainColor border-2 border-mainColor rounded-l-[5px] mt-auto mb-auto hover:cursor-pointer text-white "
                       onClick={setNumberInputPage}
                    >
                        <PlayForWorkIcon className="rotate-90"/>
                    </p>

                </div>
            </div>

            <div className="flex flex-col justify-end md:hidden">

                <div className="flex flex-col">
                    <p className="flex text-mainColor md:text-[12px] font-bold mb-[10px]">
                        <FormatListNumberedRtlIcon style={{fontSize: "22px"}}/> تعداد نمایش </p>
                    <div
                        className=" flex justify-between w-[8rem] md:w-[6rem] bg-lightColor border-2 rounded-[5px]"
                        onClick={() => setShowDropdown(!showDropdown)}>
                        <p className="mr-2 text-gray-400">
                            {itemPerPage}
                        </p>
                        <KeyboardArrowDownIcon
                            className={`text-mainColor ${showDropdown ? `rotate-180` : null}`}
                        />
                    </div>
                </div>

                <div className="flex justify-center">
                    {showDropdown ?
                        <div className="absolute flex justify-center mt-1">
                            <ul className={`flex flex-col gap-1 p-1 pr-4 w-[130px] bg-lightColor rounded-[8px] ${showDropdown ? `mb-14` : null}`}>
                                <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                    onClick={() => selectedNumber(15)}
                                >
                                    15
                                </li>
                                <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                    onClick={() => selectedNumber(30)}
                                >
                                    30
                                </li>
                                <li className="text-gray-600 hover:cursor-pointer duration-300 hover:-translate-x-1"
                                    onClick={() => selectedNumber(50)}
                                >
                                    50
                                </li>
                            </ul>
                        </div>
                        : null}
                </div>
            </div>

        </div>
    );
}


function FindTitleByFilter(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);


    const [brandNameFA, setBrandNameFA] = useState(searchParams.get("brandNameFA") || "")
    const [brandNameEN, setBrandNameEN] = useState(searchParams.get("brandNameEN") || "")
    const [genericNameFA, setGenericNameFA] = useState(searchParams.get("genericNameFA") || "")
    const [genericNameEN, setGenericNameEN] = useState(searchParams.get("genericNameEN") || "")
    const [genericCode, setGenericCode] = useState(searchParams.get("genericCode") || "")
    const [quatityInPack, setQuatityInPack] = useState(searchParams.get("quatityInPack") || "")
    const [concessionaire, setConcessionaire] = useState(searchParams.get("concessionaire") || "")

    function addUrlParams(key, value) {

        if (value)
            searchParams.set(key, value)
        navigate(location.pathname + "?" + searchParams.toString())

    }

    // function convEnNum(str){
    //     return parseFloat(str
    //         .replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)) // Convert Arabic numbers
    //         .replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)) // Convert Persian numbers
    //     );
    // }

    function sendDateToApiForFilter() {

        addUrlParams("brandNameFA", brandNameFA)
        addUrlParams("brandNameEN", brandNameEN)
        addUrlParams("genericNameFA", genericNameFA)
        addUrlParams("genericNameEN", genericNameEN)
        addUrlParams("genericCode", genericCode)
        addUrlParams("quatityInPack", quatityInPack)
        addUrlParams("concessionaire", concessionaire)
        props.setShowFilterPage(false)
        props.setCurrentPage(1)

    }


    return (

        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity">
            <div className="flex min-h-full p-3">
                <div className="rounded-lg bg-white w-[60%] md:w-[88%] p-8 md:p-0 md:pt-8 md:pb-8 m-auto gap-5">
                    <div className="grid grid-cols-2 md:flex md:flex-col md:gap-8 md:mt-3">

                        <div className="col-span-1 flex flex-col gap-8">

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={brandNameFA}
                                        id="brandNameFA"
                                        onChange={(e) => setBrandNameFA(e.target.value)}
                                        placeholder=" "

                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="brandNameFA">
                                        نام برند دارویی به فارسی
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={genericNameFA}
                                        id="genericNameFA"
                                        onChange={(e) => setGenericNameFA(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="genericNameFA">
                                        نام ژنریک دارویی به فارسی
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="number"
                                        value={quatityInPack}
                                        id="quatityInPack"
                                        onChange={(e) => setQuatityInPack(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="quatityInPack">
                                        مقدار در بسته
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={concessionaire}
                                        id="brandNameOwem"
                                        onChange={(e) => setConcessionaire(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="brandNameOwem">
                                        صاحب امتیاز
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className="col-span-1 flex flex-col gap-8">

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={brandNameEN}
                                        id="brandNameEN"
                                        onChange={(e) => setBrandNameEN(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="brandNameEN">
                                        نام برند دارویی به لاتین
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={genericNameEN}
                                        id="genericNameEN"
                                        onChange={(e) => setGenericNameEN(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="genericNameEN">
                                        نام ژنریک دارویی به لاتین
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center h-[6ch]">
                                <div className="flex bg-lightColor rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="number"
                                        value={genericCode}
                                        id="genericCode"
                                        onChange={(e) => setGenericCode(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="genericCode">
                                        کد ژنریک
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="flex justify-center gap-[10px] mt-8">
                        <button
                            className="border-[3px] w-[18%] h-[36px] md:w-[33%] text-[14px] rounded-[25px] border-mainColor bg-mainColor text-white"
                            onClick={sendDateToApiForFilter}
                        >اعمال
                            فیلتر
                        </button>
                        <button onClick={() => props.setShowFilterPage(false)}
                                className="border-[3px] w-[18%] h-[36px] text-[14px] md:w-[33%] rounded-[25px] border-mainColor text-mainColor">
                            انصراف
                        </button>
                    </div>

                </div>


            </div>
        </div>
    )
}


export function ShowDisplayFilterMobile(props) {

    useEffect(() => {
        if (!props.showButtonDeleteAllFilter)
            props.setShowDisplayFilter(false)
    }, [props.showButtonDeleteAllFilter])

    return (

        <div
            className="hidden md:fixed md:z-10 md:flex md:flex-col-2 md:top-0 md:bg-gray-600 md:bg-opacity-75 md:h-full md:w-full">

            <div
                className="md:col-span-1 md:w-[45%] md:h-full"
                onClick={() => props.setShowDisplayFilter(false)}>
            </div>


            <div
                className="md:col-span-1 md:flex md:flex-col md:w-[55%] md:h-full md:bg-white md:rounded-r-[20px] md:gap-6">

                {props.showButtonDeleteAllFilter ?
                    <div
                        className="md:flex md:gap-1 md:ml-auto md:mr-auto md:mt-10 md:w-[84%] md:bg-mainColor md:h-[4vh] md:opacity-75 md:rounded-[5pc] md:pl-3 md:pr-3 md:pt-1 md:pb-1"
                        onClick={props.deleteAllFilter}>
                        <p className="md:text-[12px] md:text-white md:font-[900] md:m-auto">حذف تمام فیلتر ها</p>
                    </div> : null}
                {props.chips.map((iteam, index) => (
                    iteam &&
                    <div key={index}
                         className="md:flex md:gap-1 md:ml-auto md:mr-auto md:w-[84%] md:rounded-[5pc] md:bg-lightColor md:border-mainColor md:border-opacity-20 md:border-[2px] md:pl-3 md:pr-3 md:pt-1 md:pb-1"
                         onClick={() => props.deleteCustomFilter(iteam)}>
                        <p><ClearIcon style={{fontSize: "16px", color: "#00C0C0", margin: "auto"}}/></p>
                        <p className="md:text-[12px] md:opacity-65 md:text-mainColor md:font-black md:m-auto">{iteam}</p>
                    </div>
                ))}


            </div>
        </div>

    )

}


export function UpdateProfile({setGetCompleteProfile, getCompleteProfile}) {

    const [loading, setLoading] = useState(false);

    const [mobile, setMobile] = useState("")

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")


    function UpdataProfile() {

        setLoading(true)
        axios.put(`${env.API_URL}/user/v1/update-profile-by-user`, {
            first_name: firstName ,
            last_name: lastName,
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then((res) => {
            message.success(res.data.message)
            setGetCompleteProfile(true)
            setLoading(false)
        }).catch((err) => {
            CheckAndHandleError(err)
            console.log(err)
            if (err.response && err.response.status <= 499) {
                message.error(err.response.data.message)
            }else
                message.error("لطفا اینترنت خود را بررسی کنید و دوباره تلاش کنید!..")
            setLoading(false)
        })

    }

    return (

        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-95 transition-opacity">
            <div className="flex min-h-full p-5">
                <div className="rounded-lg bg-white w-[50%] md:w-[88%] p-10 md:p-10 m-auto gap-5">

                    <div className="grid md:flex md:flex-col md:mt-3">

                        <div className="text-center mb-6 ">
                            متاسفانه اطلاعات شما کامل <b style={{color: 'red', fontWeight: "900"}}>نمی باشد</b> لطفا
                            اطلاعات خود را تکمیل کنید!
                        </div>

                        <div className=" flex md:flex-col gap-8 mt-5">

                            <div className="flex justify-end md:justify-normal h-[6ch] w-full">
                                <div className="flex bg-gray-100 rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={firstName}
                                        id="firstName"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="firstName">
                                        نام
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-start md:justify-normal h-[6ch] w-full">
                                <div className="flex bg-gray-100 rounded-[5px] relative top-0 left-0 w-[70%]">
                                    <input
                                        type="text"
                                        value={lastName}
                                        id="lastName"
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder=" "
                                        className="input1 outline-none bg-transparent w-full h-full text-[18px]"
                                    />
                                    <label className="label1 text-gray-400 hover:cursor-text" htmlFor="lastName">
                                        نام خانوادگی
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className="flex justify-center gap-[10px] mt-7">
                        <button
                            className="border-[3px] w-[18%] h-[36px] md:w-full justify-center text-[14px] rounded-[25px] border-mainColor bg-mainColor text-white"
                            onClick={UpdataProfile}
                        >
                            {loading ? <Loading color="bg-white"/> : "ویرایش"}

                        </button>
                    </div>

                </div>


            </div>
        </div>

    )

}