import React from "react";
import CardComponent from "./CardComponent";



const packageCard = () => {

    const packages = [
        {
            title: "پکیج اول",
            duration: "1 ماهه",
            price: "1,200,000",
            features: ["ارائه اطلاعات", "به روزرسانی داده ها"],
            buttonText: "انتخاب پکیج",
            bgColor: "#A3ECEC"
        },
        {
            title: "پکیج دوم",
            duration: "3 ماهه",
            price: "3,400,000",
            features: ["ارائه اطلاعات", "به روزرسانی داده ها"],
            buttonText: "انتخاب پکیج",
            bgColor: "#7FE5E5"
        },
        {
            title: "پکیج سوم",
            duration: "6 ماهه",
            price: "5,400,000",
            features: ["ارائه اطلاعات", "به روزرسانی داده ها"],
            buttonText: "انتخاب پکیج",
            bgColor: "#46D7D7"
        },
        {
            title: "پکیج چهارم",
            duration: "12 ماهه",
            price: "6,400,000",
            features: ["ارائه اطلاعات", "به روزرسانی داده ها"],
            buttonText: "انتخاب پکیج",
            bgColor: "#00C0C0"
        },
    ];


    return (
        <div className="flex justify-center">
            <div className="w-[90%] mb-12">
                <div className="grid grid-cols-4 gap-4 md:flex md:flex-col">
                    {packages.map((pkg, index) => (
                        <div key={index} className="col-span-1">
                            <CardComponent
                                title={pkg.title}
                                duration={pkg.duration}
                                price={pkg.price}
                                features={pkg.features}
                                buttonText={pkg.buttonText}
                                bgColor={pkg.bgColor}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default packageCard;
