import React, {useEffect} from "react";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import {useNavigate, useLocation} from "react-router-dom";
// import LockResetIcon from "@mui/icons-material/LockReset";


function ProfileHeader() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    return (

        <React.Fragment>

            <div className="md:grid md:grid-cols-3 md:justify-between">

                <div
                    className="flex md:flex-col md:col-span-1 md:items-center gap-1 justify-center p-[10px] md:mt-0 mt-[50px] bg-white md:rounded-r-[0px] rounded-r-[15px]">
                    <div className="flex">
                        <div
                            className="flex md:m-auto justify-center items-center bg-mainColor text-white w-[35px] h-[32px] border-[2px] rounded-[100%] md:ml-[0px] ml-[10px]">
                            <PermIdentityOutlinedIcon/>
                        </div>
                    </div>
                    <div className="flex  justify-start items-center md:w-full w-[65%] text-black">
                        <p className="md:m-auto">اطلاعات هویتی</p>
                    </div>
                </div>

                <div className="hover:bg-card3 md:col-span-1 duration-300 rounded-r-[15px]">
                    <div
                        className="flex md:flex-col md:items-center gap-1 justify-center w-full p-[10px] hover:cursor-pointer hover:bg-card3 duration-300 hover:-translate-x-3"
                        onClick={() => navigate("/SelectPackage")}>
                        <div className="flex ">
                            <div
                                className="flex justify-center items-center bg-white text-mainColor w-[35px] h-[32px] border-[2px] rounded-[100%] md:ml-[0px] ml-[10px]">
                                <DiamondOutlinedIcon/>
                            </div>
                        </div>
                        <div className="flex justify-start items-center md:w-full w-[65%] text-white">
                            <p className="md:m-auto">پکیج انتخابی</p>
                        </div>
                    </div>
                </div>

                <div className="hover:bg-card3 md:col-span-1 duration-300 rounded-r-[15px]">
                    <div
                        className="flex md:flex-col md:items-center gap-1 justify-center w-full p-[10px] hover:cursor-pointer hover:bg-card3 duration-300 hover:-translate-x-3"
                        onClick={() => navigate("/Reports")}>
                        <div className="flex">
                            <div
                                className="flex justify-center items-center bg-white text-mainColor w-[35px] h-[32px] border-[2px] rounded-[100%] md:ml-[0px] ml-[10px]">
                                <ReportOutlinedIcon/>
                            </div>
                        </div>
                        <div className="flex justify-start items-center md:w-full w-[65%] text-white">
                            <p className="md:m-auto">گزارشات مشکل</p>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}

export default ProfileHeader;