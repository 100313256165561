import React, { useEffect } from "react";
import RegisterHeader from "../Headers/RegisterHeader";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

const Register = () => {


    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        const path = location.pathname;
        if (!path.includes("step1") && !path.includes("step2") && !path.includes("step3")) {
            navigate("/Register/step1", { replace: true });
        }
    }, [location, navigate]);

    const handleNextStep = () => {
        const path = location.pathname;

        if (path.includes("step1")) {
            navigate("/Register/step2");
        } else if (path.includes("step2")) {
            navigate("/Register/step3");
        }
    };

    const handlePrevStep = () => {
        const path = location.pathname;
        if (path.includes("step3")) {
            navigate("/Register/step2");
        } else if (path.includes("step2")) {
            navigate("/Register/step1");
        }
    };

    return (
        <div>
            <RegisterHeader />
            <Outlet
                context={{
                    handleNextStep,
                    handlePrevStep,
                }}
            />
        </div>
    );
};

export default Register;
