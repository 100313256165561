import React from "react"
import Factor from "./Factor";


const Step3 = () => {
    return (
        <>
            <div className="mt-8 flex flex-col justify-center items-center">
                <div className=" w-[90%] flex justify-between md:flex md:gap-5 md:flex-col">
                    <div>
                        <p className="text-[18px]">
                            اطلاعات خود را بررسی کنید و برای ادامه دکمه پرداخت را فشار دهید ... </p>
                    </div>
                    <div className="flex gap-3 md:flex md:flex-col">
                        <div className="gap-2 flex items-center ">
                            <div
                                className=" flex rounded-full text-mainColor border-2 border-mainColor justify-center w-[28px] h-[28px] ">
                                <span className="text-[16px]">1</span>
                            </div>
                            <p className="text-[#7E7E81]">ورود اطلاعات</p>
                            <span className="text-[#7E7E81] md:hidden"> . . . </span>
                        </div>
                        <div className="col-span-1 gap-2 flex items-center">
                            <div
                                className=" flex rounded-full text-mainColor border-2 border-mainColor justify-center w-[28px] h-[28px] ">
                                <span className="text-[16px]">2</span>
                            </div>
                            <p className="text-[#7E7E81]"> انتخاب پکیج </p>
                            <span className="text-[#7E7E81] md:hidden"> . . . </span>
                        </div>
                        <div className="col-span-1 gap-2 flex items-center">
                            <div
                                className=" flex items-center rounded-full text-white bg-mainColor justify-center w-[28px] h-[28px] ">
                                <span className="text-[16px]">3</span>
                            </div>
                            <p className=""> تایید اطلاعات و پرداخت </p>
                            <span className="md:hidden"> . . . </span>
                        </div>
                    </div>
                </div>
            </div>
            <Factor/>
        </>

    )
}

export default Step3;