import React, {useState, useEffect} from 'react';
import AddNewProblem from "./AddNewProblem";
import TitleProblem from "./TitleProblem";
import MainProblems from "./MainProblems";
import DehazeIcon from "@mui/icons-material/Dehaze";
import ReportsHeader from "./ReportsHeader";
import {useMediaQuery} from "react-responsive";
import Header from "../../Headers/Header";
import {useNavigate, useLocation} from "react-router-dom";


function ManagementComponent() {

    const [addProblem, setAddProblem] = useState(false);
    const [showProblem, setShowProblem] = useState(false);

    const [titleReport, setTitleReport] = useState("")
    const [timeReport, setTimeReport] = useState("")
    const [textReport, setTextReport] = useState("")
    const [status, setSatus] = useState("")
    const [answer, setAnswer] = useState("")

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    return (
        <React.Fragment>
            <Header/>
            <div className="grid grid-cols-12 h-screen md:contents md:contents-col-12">
                <div className="col-span-2 bg-mainColor md:fixed md:inset-x-0 md:bottom-0 md:col-span-2">
                    <ReportsHeader/>
                </div>

                <div className="col-span-10 md:col-span-10 md:mb-[140px]">

                    {addProblem ?
                        <AddNewProblem setAddProblem={setAddProblem} />
                        :
                        showProblem ?
                            <TitleProblem titleReport={titleReport} answer={answer} timeReport={timeReport} textReport={textReport} setShowProblem={setShowProblem} status={status}/>
                            :
                            <MainProblems setShowProblem={setShowProblem} setAnswer={setAnswer} setTitleReport={setTitleReport} setTextReport={setTextReport} setSatus={setSatus} setTimeReport={setTimeReport} setAddProblem={setAddProblem}/>}
                </div>
            </div>


        </React.Fragment>
    )
}

export default ManagementComponent;