import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router-dom";

const RegisterHeader = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div className="bg-Headercolor w-full h-[90px] flex items-center justify-center">
                <div className="w-[90%] flex justify-between items-center">
                    <div>
                        <p className="text-[22px] font-bold">
                            ثبت نام
                        </p>
                    </div>
                    <div className="border-[3px] rounded-[4px] hover:cursor-pointer border-mainColor w-fit h-fit p-[1px] bg-lightColor flex items-center justify-center"
                            onClick={() => navigate("/1")}
                    >
                        <CloseIcon className="text-mainColor" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterHeader;
