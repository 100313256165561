import React from "react";


function TitleProblem(props) {


    return (

        <div className="flex justify-center items-center">
            <div className="w-[88%] flex flex-col justify-between mt-[50px]">
                <div className="gap-9 grid">

                    <div className="text-[25px] md:text-[20px]">{props.titleReport}</div>

                    <div className="flex flex-col gap-2">

                        <div className="gap-5 inline-flex grid-cols-2">
                            <div className="col-span-1 md:text-[12px]">
                                <p>تاریخ و زمان ارسال گزارش:</p>
                            </div>
                            <div className="col-span-1 md:text-[12px]">
                                <p>{props.timeReport}</p>
                            </div>
                        </div>

                        <div className="gap-[85px] inline-flex grid-cols-2">
                            <div className="col-span-1 md:text-[12px]">
                                <p>وضعیت گزارش:</p>
                            </div>
                            <div className="col-span-1 md:text-[12px]">
                                <p className={`${props.status === "بررسی نشده" ? `text-red-500` : `text-mainColor`}`}>{props.status}</p>
                            </div>

                        </div>
                    </div>


                    <div className="grid gap-5">
                        <div className="md:text-[15px]">متن گزارش:</div>
                        <div className="bg-lightColor rounded-[12px]">
                            <textarea
                                className="bg-transparent p-[12px] md:text-[14px]  h-[200px] mr-[10px] w-[99%] resize-none outline-none"
                                value={props.textReport}/>
                        </div>
                    </div>

                    <div className="grid gap-5">
                        <div className="md:text-[15px]">پاسخ گزارش:</div>
                        <div className="bg-lightColor rounded-[12px]">
                            <textarea
                                className="bg-transparent p-[12px] md:text-[14px]  h-[200px] mr-[10px] w-[99%] resize-none outline-none"
                                value={props.answer ?? "بدون پاسخ"}/>
                        </div>
                    </div>

                </div>

                <div className="flex justify-end md:justify-center mt-[28px]">
                    <button onClick={() => props.setShowProblem(false)}
                            className="border-[2px] md:h-[36px] w-[20%] md:w-[40%] md:text-[19px] h-[49px] bg-mainColor text-white rounded-[8px]">بستن
                    </button>
                </div>


            </div>

        </div>
    )
}

export default TitleProblem