import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import {useSelector} from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



function HeaderTiltle() {

    let location = useLocation();
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownProfile, setShowDropdownProfile] = useState(false);
    const profileInformation = useSelector(state => state.userinformation.userInfo);

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    const handlelogOut = () => {
        localStorage.clear()
        window.location.reload()
    }
    return (
        <ul className="flex flex-row gap-10 justify-center md:inline h-full">
            <li className="menu-item md:mt-[15px]">
                <div>
                    <div
                        className={`grid hover:text-mainColor hover:cursor-pointer`}
                        onClick={() => {setShowDropdown(!showDropdown); setShowDropdownProfile(false)}}>
                        <h4 className=" select-none border-mainColor">اطلاعات دارویی<KeyboardArrowDownIcon
                            className={`hover:text-mainColor ${showDropdown ? `rotate-180` : null}`}/></h4>
                    </div>
                    {showDropdown ?
                        <div className="absolute bg-white border-2 border-mainColor rounded-[6px] mt-3">
                            <ul className="w-[260px] md:w-[90%]">
                                <li className="hover:cursor-pointer hover:text-mainColor m-2 md:text-[12px]"
                                    onClick={() => {navigate("/medicineA"); setShowDropdown(false)}}>اطلاعات دارویی (دسته بندی A)
                                </li>
                                <hr className="border-1 border-mainColor "/>
                                <li className="hover:cursor-pointer hover:text-mainColor m-2 md:text-[12px]"
                                    onClick={() => {navigate("/medicineB"); setShowDropdown(false)}}>اطلاعات دارویی (دسته بندی B)
                                </li>
                            </ul>
                        </div> : null}
                </div>
            </li>
            <li className="menu-item md:mt-[15px]">
                <Link to={"https://carbistt.ir/about-us/"} className=" border-mainColor hover:text-mainColor">درباره
                    ما</Link>
            </li>
            <li className="menu-item md:mt-[15px]">
                <Link to={"https://carbistt.ir/guide/"} className=" border-mainColor hover:text-mainColor">راهنمای
                    سایت</Link>
            </li>
            <li className="menu-item md:mt-[15px]">
                <Link to={"https://carbistt.ir/roles/"} className=" border-mainColor hover:text-mainColor">قوانین
                    و مقررات</Link>
            </li>
            <li className="menu-item md:mt-[15px]">
                <Link to={"https://carbistt.ir/blog/"}
                      className=" border-mainColor hover:text-mainColor">مقالات</Link>
            </li>

            <li className="menu-item md:mt-[15px]">
                <Link to={"https://carbistt.ir/news/"}
                      className=" border-mainColor hover:text-mainColor">اخبار</Link>
            </li>
            <li className="menu-item md:mt-[15px]">
                <div>
                    <div
                        className={`flex hover:text-mainColor hover:cursor-pointer gap-1`}
                        onClick={() => {setShowDropdownProfile(!showDropdownProfile); setShowDropdown(false)}}>
                        <h4 className=" select-none border-mainColor">پروفایل کاربری<KeyboardArrowDownIcon
                            className={`hover:text-mainColor ${showDropdownProfile ? `rotate-180` : null}`}/></h4>
                    </div>
                    {showDropdownProfile ?
                        <div className="absolute w-[220px] bg-white border-2 border-mainColor rounded-[6px] mt-3">
                            <ul className="">
                                <li className="flex justify-between gap-10 hover:cursor-pointer m-2 md:text-[15px]"
                                    onClick={() => navigate("/Profile")}>
                                    <h4>{profileInformation.name} {profileInformation.lastName}</h4>
                                    <h4 className="text-mainColor"><KeyboardArrowLeftIcon/></h4>
                                </li>
                                <hr className="border-1 border-mainColor"/>
                                <li onClick={handlelogOut}
                                    className="flex justify-between gap-10 hover:cursor-pointer m-2 md:text-[15px]">
                                    <div className="text-red-600">خروج از حساب</div>
                                    <div className="text-red-500"><LogoutIcon/></div>
                                </li>
                            </ul>
                        </div> : null}
                </div>
            </li>
        </ul>
    )
}

export default HeaderTiltle;