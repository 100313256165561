import React, {useEffect, useState} from "react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// import DehazeIcon from "@mui/icons-material/Dehaze";
// import {useMediaQuery} from "react-responsive";
import SelectPackageHeater from "./SelectPackageHeater";
import Header from "../../Headers/Header";
import axios from "axios";
import {message} from "antd";
import env from "react-dotenv";
import {CheckAndHandleError} from "../../../axios/HandleAcsess";
import {useNavigate, useLocation} from "react-router-dom";




function SelectPackage() {

    const navigate = useNavigate();
    const location = useLocation();

    const [getdate, setGetDate] = useState([]);

    useEffect(() => {

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken || accessToken.length <= 0) {
            navigate("/")
        }

    }, [navigate, location]);

    useEffect(() => {
        axios.get(`${env.API_URL}/user/v1/get-my-profile`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then((response) => {
                setGetDate(response.data.data);
            }).catch((error) => {
            CheckAndHandleError(error);
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!")
            }
            if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...")
        })
    }, []);


    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        const date = new Date(timestamp);
        return date.toLocaleString('fa-IR');
    };




    return (
        <React.Fragment>
            <Header/>

            <div className="grid grid-cols-12 h-screen md:contents md:contents-col-12">

                <div className="col-span-2 bg-mainColor md:fixed md:inset-x-0 md:bottom-0 md:col-span-2">
                    <SelectPackageHeater/>
                </div>

                <div className="col-span-10 md:col-span-10">
                    <div className="flex justify-center items-center md:mb-[100px]">
                        <div className="w-[88%] md:w-[90%]">
                            <div className="mt-[55px] md:mt-4">
                                <div className="grid md:grid-cols-1 grid-cols-2">
                                    <div className="col-span-1 flex">
                                        <div className="w-full">

                                            <div>
                                                <div className="flex items-center">
                                                    <ArrowBackIosNewOutlinedIcon
                                                        className="text-mainColor text-[17px]"/>
                                                    <h3 className="text-[15px]">نام پکیج</h3>
                                                </div>
                                                <div
                                                    className="p-3 mt-3 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                    <input
                                                        className="bg-transparent w-full h-[24px] outline-none pr-[12px] placeholder:text-[18px]"
                                                        placeholder={"نام پکیج"}/>
                                                </div>
                                            </div>


                                            <div>
                                                <div className="flex mt-8 items-center">
                                                    <ArrowBackIosNewOutlinedIcon
                                                        className="text-mainColor text-[17px]"/>
                                                    <h3 className="text-[15px]">تاریخ خرید</h3>
                                                </div>
                                                <div
                                                    className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                    <input
                                                        className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                        type={"password"} placeholder={"تاریخ"}/>
                                                </div>
                                            </div>


                                        </div>

                                    </div>


                                    <div className="col-span-1">
                                        <div
                                            className="w-full h-[75vh] md:h-[60vh] flex justify-between flex-col">
                                            <div>

                                                <div>
                                                    <div className="flex items-center">
                                                        <ArrowBackIosNewOutlinedIcon
                                                            className="text-mainColor text-[17px]"/>
                                                        <h3 className="text-[15px]">قیمت</h3>
                                                    </div>
                                                    <div
                                                        className="p-3 mt-3 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                        <input
                                                            className="bg-transparent w-full h-[24px] outline-none pr-[12px] placeholder:text-[18px]"
                                                            placeholder={"قیمت پکیچ"}/>
                                                    </div>
                                                </div>


                                                <div>
                                                    <div className="flex mt-8 items-center">
                                                        <ArrowBackIosNewOutlinedIcon
                                                            className="text-mainColor text-[17px]"/>
                                                        <h3 className="text-[15px]">تاریخ</h3>
                                                    </div>
                                                    <div
                                                        className="p-3 mt-2 w-[82%] md:w-full bg-gray-200 rounded-lg">
                                                        <input
                                                            className="bg-transparent w-full h-[24px] outline-none pr-[12px]"
                                                            type={"text"} placeholder={"تاریخ انقضا"} value={getdate.validity ? formatTimestamp(getdate.validity) : "تاریخ انقضا وجود ندارد"}/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className=" md:mt-[32px] mt-10 flex gap-2 mb-8">
                                                <button
                                                    className="w-[40%] md:w-[49%] md:h-[36px] h-[43px] rounded-[6px] bg-mainColor text-white">ویرایش
                                                </button>
                                                <button
                                                    className="border-[3px] md:h-[36px] w-[40%] md:w-[49%] h-[43px] rounded-[6px] border-mainColor text-mainColor">انصراف
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </React.Fragment>
    )
}


export default SelectPackage;