import React from "react"


const Loading = ({color}) => {
    return (
        <div className="flex gap-1 justify-center">
            <div className={`h-2 w-2 ${color} rounded-full animate-bounce [animation-delay:-0.3s]`}></div>
            <div className={`h-2 w-2 ${color} rounded-full animate-bounce [animation-delay:-0.15s]`}></div>
            <div className={`h-2 w-2 ${color} rounded-full animate-bounce`}></div>
        </div>
    )
}

export default Loading