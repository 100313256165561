import React, {useEffect, useState, useRef} from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import img from "../../images/Login.webp";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {message, Modal, Button} from "antd";
import Cookies from 'js-cookie';
import Loading from "../Loading/Loading";
import {encrypt, decrypt} from "n-krypta"
import {urlKIMI} from "../../axios/Axios";
import env from 'react-dotenv'



const Login = () => {


    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [userName, setUserName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSubmitOtp, setShowSubmitOtp] = useState(false)

    const [otp, setOtp] = useState(Array(4).fill(''));
    const inputRefs = useRef([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    // بستن مودال
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            navigate("/medicineA");
        }
    }, [navigate]);


    function EnterLogin() {
        setLoading(true);
        axios.post(`${env.API_URL}/user/v1/send-otp`, {
            mobile: userName,
        }).then((response) => {
            if (response.status === 200) {
                message.success(response.data.message);
                setLoading(false);
                setShowSubmitOtp(true)
                setOtp(Array(4).fill(''))
            }
        }).catch((error) => {
            setLoading(false);
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!");
            }
            if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else {
                message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...");
            }
        });
    }

    console.log(env.VITE_API_URL)

    function EnterSubmitOTP() {
        setLoading(true);
        axios.post(`${env.API_URL}/user/v1/submit-otp`, {
            mobile: userName,
            code: otp.slice().join(''),
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem("accessToken", response.data.data.accessToken);
                localStorage.setItem("refreshToken", response.data.data.refreshToken);
                setLoading(false);
                navigate("/medicineA");
            }
        }).catch((error) => {
            setLoading(false);
            if (error.message === "Network Error") {
                message.error("اینترنت خود را بررسی کنید ..!");
            } else if (error.response && error.response.status <= 499) {
                message.error(error.response.data.message);
            } else {
                message.error("مشکلی پیش امده، لطفا دوباه تلاش کنید!...");
            }
        });
    }

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value.length === 1 && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };


    
    return (
        <div>
            <div className="grid grid-cols-2 md:grid-cols-1">
                <div className="col-span-1">
                    <div className="flex  items-center justify-center w-full h-[100vh]">
                        <div className="md:w-full p-6 w-[50%] flex flex-col justify-center">
                            {showSubmitOtp ?
                                <div className="flex flex-col gap-8">
                                    <div className="flex flex-col justify-center items-center w-[100%]">
                                        <p className="text-[22px] font-[500]">کد تأیید را وارد کنید</p>
                                        <p className="gap-1 flex text-[#8A8D97] text-[16px] mt-4">
                                            کد تایید به شماره
                                            <p className="text-black text-[16px] font-[600]">{userName}</p>
                                            ارسال شد
                                        </p>
                                        <div style={{direction: "ltr"}} className="flex gap-4 mt-6 ">
                                            {otp.map((digit, index) => (
                                                <input
                                                    ref={el => inputRefs.current[index] = el}
                                                    key={index}
                                                    type="text"
                                                    maxLength={1}
                                                    value={digit}
                                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                    className={`text-[26px] md:w-[40px] md:h-[50px] w-[50px] h-[68px] text-center border border-gray-300 rounded-md focus:outline-none focus:border-[#100E34] ${digit ? 'input-filled' : ''}`}
                                                    style={{direction: 'ltr'}}
                                                    pattern="[0-9]"
                                                    autoFocus={index === 0}
                                                />
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="flex mb-5">
                                                <p className="text-[16px]">شماره موبایل اشتباه است ؟</p>
                                                <p
                                                    className="text-[16px] text-mainColor cursor-pointer"
                                                    onClick={() => {
                                                        setShowSubmitOtp(false);
                                                        setOtp(Array(4).fill(''))
                                                    }}>ویرایش</p>
                                            </div>

                                        </div>

                                        <button
                                            disabled={loading === true}
                                            onClick={EnterSubmitOTP}
                                            className={`flex items-center  justify-center bg-mainColor w-[100%] rounded-[10px] h-[44px] ${loading ? `hover:cursor-not-allowed` : null}`}
                                        >
                                            {loading ? (
                                                <Loading color={"bg-white"}/>
                                            ) : (
                                                <div>
                                                    <p className="text-white text-[18px]">تایید</p>
                                                </div>
                                            )}
                                        </button>
                                    </div>

                                    <div className="flex justify-between w-[100%]">
                                        <div className="flex items-center gap-1">
                                            <HeadphonesOutlinedIcon
                                                style={{fontSize: "20px"}}
                                                className="text-mainColor"
                                            />
                                            <button
                                                onClick={showModal}
                                                className="text-black text-[14px] md:text-[12px] font-[400] border-none bg-transparent cursor-pointer"
                                            >
                                            تماس با پشتیبانی
                                            </button>
                                        </div>
                                        <div className="flex">
                                            <p className="text-[14px]">حساب کاربری ندارید؟ </p>
                                            <Link to="/Register" className="text-mainColor text-[14px] mr-1">
                                                {" "}
                                                ثبت نام{" "}
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                                :
                                <>
                                    <h3 className="text-[28px] md:text-[22px] font-[700] h-[80px]">
                                        ورود به حساب کاربری
                                    </h3>
                                    <div className="flex flex-col gap-8">
                                        <div>
                                            <div className="flex items-center gap-[6px]">
                                                <PersonOutlineIcon
                                                    style={{fontSize: "28px"}}
                                                    className="text-mainColor"
                                                />
                                                <p className="text-[18px] font-[400] md:text-[16px]">شماره موبایل</p>
                                            </div>
                                            <div
                                                className="mt-3 bg-lightColor h-[44px] rounded-[5px]">
                                                <input
                                                    className=" md:placeholder:text-[14px] outline-none focus:outline-gray-400 text-right text-[18px] placeholder:text-[16px] placeholder:text-right  bg-lightColor w-[100%] h-[44px] p-2 rounded-[5px]"
                                                    type="number"
                                                    value={userName}
                                                    placeholder="شماره موبایل خود را وارد کنید ..."
                                                    onChange={(name) => {
                                                        setUserName(name.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                            <button
                                                disabled={loading === true}
                                                onClick={EnterLogin}
                                                className={`flex items-center  justify-center bg-mainColor w-[100%] rounded-[10px] h-[44px] ${loading ? `hover:cursor-not-allowed` : null}`}
                                            >
                                                {loading ? (
                                                    <Loading color={"bg-white"}/>
                                                ) : (
                                                    <div>
                                                        <p className="text-white text-[18px]">ورود</p>
                                                    </div>
                                                )}
                                            </button>
                                        </div>

                                        {/* دکمه تماس با پشتیبانی */}
                                        <div className="flex justify-between w-[100%]">
                                            <div className="flex items-center gap-1">
                                                <HeadphonesOutlinedIcon
                                                    style={{fontSize: "20px"}}
                                                    className="text-mainColor"
                                                />
                                                <button
                                                    onClick={showModal}
                                                    className="text-black text-[14px] md:text-[12px] font-[400] border-none bg-transparent cursor-pointer"
                                                >
                                                    تماس با پشتیبانی
                                                </button>
                                            </div>
                                            <div className="flex">
                                                 <p className="text-[14px]">حساب کاربری ندارید؟ </p>
                                                <Link to="/Register" className="text-mainColor text-[14px] mr-1">
                                                    {" "}
                                                    ثبت نام{" "}
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </>

                            }

                        </div>
                    </div>
                </div>
                <div className="col-span-1 md:hidden">
                    <img className="max-h-[100vh] w-full h-full" src={img}/>
                </div>
            </div>

            {/* مودال تماس با پشتیبانی */}
            <Modal
                title="تماس با پشتیبانی"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null} // حذف دکمه‌های OK و Cancel
            >
                <p> با شماره 09123456789 تماس بگیرید.</p>
                <a href="tel:09123456789">
                    <button className="bg-mainColor text-white rounded-[8px] px-4 py-2 mt-4">
                        تماس با شماره
                    </button>
                </a>
            </Modal>
        </div>
    );
};

export default Login;
